// LoadingSpinner.js
import React from "react";

const LoadingSpinner = () => (
  <svg
    className="animate-spin h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="spinnerGradient" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor="#fff" stopOpacity="0" />
        <stop offset="100%" stopColor="#fff" stopOpacity="1" />
      </linearGradient>
      <filter id="glow">
        <feGaussianBlur stdDeviation="1.5" result="coloredBlur" />
        <feMerge>
          <feMergeNode in="coloredBlur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="url(#spinnerGradient)"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="url(#spinnerGradient)"
      d="M12 2v10M12 22v-10M22 12h-10M2 12h10"
      filter="url(#glow)"
    ></path>
  </svg>
);

export default LoadingSpinner;
