import React, { useEffect } from "react";

const Toast = ({ message, show, onClose, type }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) return null;

  const bgColor = type === "success" ? "bg-green-500" : "bg-red-500";
  const icon = type === "success" ? "✅" : "⚠️"; // Icons can be replaced with SVG or FontAwesome icons

  return (
    <div
      className={`fixed bottom-5 right-5 md:bottom-10 md:right-10 flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${bgColor} focus:outline-none focus:ring-2 focus:ring-offset-2`}
    >
      <span className="mr-2">{icon}</span>
      {message}
    </div>
  );
};

export default Toast;
