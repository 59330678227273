import React from "react";
import { BsRocketTakeoff } from "react-icons/bs";
import { BiCodeAlt } from "react-icons/bi";
import { GrUserExpert, GrLaunch } from "react-icons/gr";
import { RiPaintBrushLine } from "react-icons/ri";
import { AiOutlineGift } from "react-icons/ai";
import { PiAndroidLogoBold } from "react-icons/pi";
import SalesCard from "../Card/SalesCard";

function MediaAds() {
  return (
    <div className="bg-sales_bg_purple md:h-[1050px] lg:h-[950px]  pb-16 md:pb-0">
      <div className="pt-24 flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center md:max-w-4xl text-center px-4 md:px-2">
          <p className="font-bold text-3xl md:text-4xl lg:text-5xl">
            Preserve <span className="text-purple_fixed">Thousands</span> in
            advertising expenses with our
            <span className="text-green-500 flex-nowrap"> Assured</span>{" "}
            scheduled meetings.
          </p>
          <p className="mt-5 font-semibold px-2 md:px-4">
            Your return on investment is pivotal to our mutual success. We
            manage the demanding tasks, allowing you to concentrate on your
            outstanding product while observing your ROI soar.
          </p>
          <div className="text-center block pt-2">
            <BsRocketTakeoff color="red" size={20} />
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <SalesCard
            logo={BiCodeAlt}
            headingText={"Guaranteed Booked Meetings"}
            paragraph={
              "Instead of investing in the uncertain potential of lead generation, we provide you with appointments that are not only qualified but already scheduled!"
            }
          />
          <SalesCard
            logo={GrUserExpert}
            headingText={"Rapid Verification Process"}
            paragraph={
              "Our proven system meticulously evaluates each lead, guaranteeing that every contact presents a viable opportunity for your business's growth."
            }
          />
          <SalesCard
            logo={GrLaunch}
            headingText={"Ready-to-Deploy Platform"}
            paragraph={
              "With our outstanding CRM system, we ensure that every lead generated for your business is thoroughly nurtured, leading to conversions at times you might not anticipate."
            }
          />

          <SalesCard
            logo={RiPaintBrushLine}
            headingText={"Tailor-Made for Your Brand"}
            paragraph={
              "Our bespoke solutions enable us to adjust our Lead Generation strategies to guarantee an impeccable alignment with your business needs."
            }
          />
          <SalesCard
            logo={AiOutlineGift}
            headingText={"Comprehensive Sales Support"}
            paragraph={
              "Beyond generating leads, we possess the necessary expertise and skilled professionals to effectively convert those prospects. This translates to a consistent revenue stream delivered straight to your business each month."
            }
          />
          <SalesCard
            logo={PiAndroidLogoBold}
            headingText={"Return on Investment"}
            paragraph={
              "Let’s face it, a positive ROI is paramount; your success drives ours. We flourish only when your business does."
            }
          />
        </div>
        {/* Another set of SalesCard components */}
      </div>
    </div>
  );
}

export default MediaAds;
