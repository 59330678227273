import React, { useEffect, useRef, useState } from "react";
import digitalMarketingLabAiLogoWithoutbg from "../../media/digitalMarketingLabAiLogoWithoutbg.png";
import { useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const goToPage = (link) => {
    navigate(link);
  };

  const handleGoToPageFromMobileScreen = (link) => {
    navigate(link);
    setIsOpen(false);
  };

  const closeMenu = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", closeMenu);
    }
    return () => {
      window.removeEventListener("click", closeMenu);
    };
  }, [isOpen]);

  return (
    <header className="w-full h-14 md:h-20 bg-white shadow-xl sticky top-0 z-50">
      <div className="flex justify-between items-center px-4 md:px-8 lg:px-12 xl:px-20 py-2">
        <img
          src={digitalMarketingLabAiLogoWithoutbg}
          onClick={() => goToPage("/")}
          alt="Logo"
          className="cursor-pointer w-24 h-12 sm:w-28 sm:h-14 md:w-28 md:h-14 lg:w-32 lg:h-16"
        />
        <div className="hidden md:flex items-center space-x-4">
          <a href="#" className="hover:underline" onClick={() => goToPage("/")}>
            Home
          </a>
          <a
            href="#"
            className="hover:underline"
            onClick={() => goToPage("/caseStudies")}
          >
            Case Studies
          </a>
          <a
            href="#"
            className="hover:underline"
            onClick={() => goToPage("/faq")}
          >
            F.A.Q
          </a>
          <a
            href="#"
            className="hover:underline"
            onClick={() => goToPage("/subscribe")}
          >
            Pricing
          </a>
          <button
            onClick={() => goToPage("/booking-page")}
            className="rounded-lg bg-gradient-to-r from-button_yellow to-purple_fixed text-white font-bold py-2 px-4 transition duration-500 ease-in-out transform"
          >
            Book Free Call
          </button>
        </div>
        <div className="md:hidden" ref={menuRef}>
          <BiMenu size={30} onClick={() => setIsOpen(!isOpen)} />
          {isOpen && (
            <div className="absolute right-0 z-20 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg">
              <a
                onClick={() => handleGoToPageFromMobileScreen("/")}
                className="text-gray-700 block px-4 py-2 text-sm"
              >
                Home
              </a>
              <a
                onClick={() => handleGoToPageFromMobileScreen("/caseStudies")}
                className="text-gray-700 block px-4 py-2 text-sm"
              >
                Case Studies
              </a>
              <a
                onClick={() => handleGoToPageFromMobileScreen("/faq")}
                className="text-gray-700 block px-4 py-2 text-sm"
              >
                F.A.Q
              </a>
              <a
                onClick={() => handleGoToPageFromMobileScreen("/booking-page")}
                className="text-gray-700 block px-4 py-2 text-sm"
              >
                Book Free Call
              </a>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
