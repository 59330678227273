import React from "react";

function AppointmentCard({
  bgColor: Color,
  icon: Icon,
  iconColor,
  iconBgColor: iconBGColor,
  heading,
  text,
}) {
  return (
    <div className="bg-white mx-4 my-4 md:mx-auto md:w-96 rounded-3xl z-10 transform shadow-lg pb-5">
      <div
        style={{ backgroundColor: Color }}
        className={`h-44 rounded-t-3xl`}
      ></div>
      <div className="flex flex-col items-center justify-center -mt-12 md:-mt-18">
        <div
          className="bg-white z-10 transform translate-y-[-50px] h-24 md:h-36 w-28 md:w-40 lg:w-72 rounded-3xl flex flex-col items-center justify-center p-4"
          style={{ backgroundColor: iconBGColor }}
        >
          <Icon size={60} color={iconColor} />
        </div>
        <div className="flex flex-col items-center justify-center mt-5">
          <p className="font-bold text-xl md:text-2xl text-[#211F54]">
            {heading}
          </p>
          <p className="text-center pt-5 px-5 md:text-lg">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default AppointmentCard;
