import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import digitalMarketingLabAiLogoWithoutbg from "../../media/digitalMarketingLabAiLogoWithoutbg.png";

const BookingPage = () => {
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload),
  });
  return (
    <div className="flex pt-3 bg-white w-full">
      <div className="flex md:flex-row flex-col w-full">
        <div className="md:w-1/4 w-full ">
          <div className="md:pt-20 pl-4 pr-5 md:pr-0 md:pl-10 pt-7">
            <div className="flex ">
              <img
                src={digitalMarketingLabAiLogoWithoutbg}
                alt="Logo"
                className="w-36 md:w-40 h-28 md:h-28 mr-2"
              />
            </div>
            <div className="pt-10">
              <p className="font-bold text-3xl">
                Digital Marketing Lab Discovery Session
              </p>
            </div>
            <div className="pt-8">
              <p className="text-gray-400">
                Requesting a discovery session with Digital Marketing Lab offers
                a valuable chance to delve into your business objectives and
                discover how our extensive lead generation services can
                contribute to your success. Our specialists are ready to walk
                you through the advantages and functionalities of our services,
                assisting you in navigating potential collaboration
                opportunities. During this consultation, you'll receive tailored
                guidance on enhancing your client engagement tactics, aiming to
                elevate your business’s performance and reach.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full mt-5 md:mt-0 md:w-3/4">
          <InlineWidget
            styles={{ width: "100%", height: "900px" }}
            url="https://calendly.com/digitalmarketinglabusa/30min?hide_gdpr_banner=1"
          />
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
