import React from "react";
import newCase from "../../media/newcase.jpg";
import newResult from "../../media/newResult.jpg";

function CaseStudiesPage() {
  return (
    <div className="bg-gray-50 md:h-screen">
      <div className="px-6 md:px-20 pt-16 md:pt-32 pb-10">
        <div>
          <p className="md:text-lg">Insight from our clients.</p>
          <p className="pt-14 text-5xl md:text-6xl font-semibold md:font-bold">
            Case Study: <span className="text-purple_fixed">Symantek, LLC</span>
          </p>
          <p className="pt-5 md:pt-7 md:text-lg">
            Now at over{" "}
            <span className="text-green-600 ">+15 booked calls</span> and
            several closed deals.
          </p>
          <button className="mt-8 md:mt-10 rounded-lg h-12 bg-gradient-to-r from-button_yellow to-purple_fixed text-white font-bold py-2 px-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            Book Free Call
          </button>
        </div>
        <div className="flex mt-10 md:pr-36 justify-center items-center h-72 md:h-[800px] ">
          <img src={newCase} alt="description" className="w-full h-full" />
        </div>
        <div className="pt-20 pl-3 md:pl-10 pr-10 ">
          <div>
            <p className="font-semibold text-2xl md:text-4xl">
              Getting Started
            </p>
            <p className="pt-3 md:pt-6 text-sm md:text-lg">
              In today’s digital networking landscape, it’s not just about
              broadening your reach—it’s about establishing deep, meaningful
              connections. Embracing this philosophy, we at Digital Marketing
              Lab recently embarked on a transformative journey with Symantek,
              LLC. In just five weeks, we were able to unlock the potential of
              LinkedIn Automation, delivering tangible results that translated
              into solid business leads and successful deals.
            </p>
          </div>
          <div className="pt-16">
            <p className="font-semibold text-2xl md:text-4xl">Background</p>
            <p className="pt-3 md:pt-6 text-sm md:text-lg">
              Symantek, LLC, an emerging force in the tech industry,
              acknowledged the potential of LinkedIn for reaching a strategic
              audience but faced uncertainty. The looming questions were: Would
              the investment yield measurable outcomes? Could they secure a
              partner who would authentically grasp and elevate their brand’s
              presence? This is where our proficiency at Digital Marketing Lab
              became crucial.
            </p>
          </div>
          <div className="pt-16">
            <p className="font-semibold text-2xl md:text-4xl">Goal Setting</p>
            <p className="pt-3 md:pt-6 text-sm md:text-lg">
              The roadmap we laid out for Symantek, LLC was clear and bold:
            </p>
            <div className="pl-4 pt-3 text-sm md:text-lg">
              <p>
                1. Develop a tailor-made, focused message sequence that captures
                the unique spirit of Symantek, LLC’s brand.
              </p>
              <p className="pt-2">
                2. Identify and actively connect with Symantek, LLC’s ideal
                audience on LinkedIn.
              </p>
              <p className="pt-2">
                3. Integrate the insights and vision of Madelyn, the driving
                force behind Symantek, LLC, into our automation procedures,
                ensuring our outreach was both authentic and customized.
              </p>
            </div>
          </div>
          <div className="pt-16">
            <p className="font-semibold text-2xl md:text-4xl">
              Strategy and Execution
            </p>
            <p className="pt-3 md:pt-6 text-sm md:text-lg">
              Operating with a single account, we immersed ourselves in
              comprehending Symantek, LLC’s business structure, objectives, and
              Madelyn’s vision for the company. The key was to ensure our
              automation didn’t come across as ‘automated’. Every sequence,
              every message needed to convey a personal touch.
            </p>
            <div className="pl-4 pt-3 text-sm md:text-lg">
              <ul className="list-disc pl-1 md:pl-5">
                <li className="mb-2">
                  <strong>Targeted Sequence Copy:</strong> Utilizing insights
                  gathered from our dialogues with Ali and understanding the
                  broader ambitions of Symantek, LLC, we crafted message
                  sequences that directly appealed to their target demographic.
                  This approach made conversations flow more naturally,
                  resulting in heightened engagement.
                </li>
                <li className="mb-2">
                  <strong>Demographic Targeting:</strong> We steered away from a
                  broad, indiscriminate approach, focusing instead on the
                  precise customer profiles ideal for Symantek, LLC. This
                  strategy not only ensured relevance in our outreach but also
                  amplified the chances of receiving positive responses.
                </li>
                <li className="mb-2">
                  <strong>Personalized Touch:</strong> Infusing Madelyn’s unique
                  voice into our automated processes ensured that every point of
                  contact felt as if it was coming straight from him. This
                  personalization played a crucial role in building trust and
                  laying the foundation for meaningful interactions.
                </li>
              </ul>
            </div>
          </div>
          <div className="pt-16">
            <p className="font-semibold text-2xl md:text-4xl">
              Results Achieved
            </p>
            <p className="pt-3 md:pt-6 text-sm md:text-lg">
              The outcomes are a clear indicator of our success:
            </p>
            <div className="pl-4 pt-3 text-sm md:text-lg">
              <ul className="list-disc pl-1 md:pl-5">
                <li className="mb-2">
                  More than 15 calls scheduled within a short span of 5 weeks,
                  demonstrating the effectiveness of our outreach efforts.
                </li>
                <li className="mb-2">
                  Several deals successfully closed, playing a significant role
                  in propelling Symantek, LLC forward.
                </li>
                <li className="mb-2">
                  An impressive attendance rate for these calls, with only one
                  person not showing up (and they communicated their change of
                  interest), highlighting the precision of our targeted
                  strategy.
                </li>
              </ul>
              <div className="rounded-3xl border-[1px] border-black overflow-hidden mt-10 md:mr-40 md:h-[800px]">
                <img
                  src={newResult}
                  alt="description"
                  className=" w-full h-full"
                />
              </div>
            </div>
          </div>
          <div className="pt-16">
            <p className="font-semibold text-2xl md:text-4xl">Wrapping Up</p>
            <p className="pt-3 md:pt-6 text-sm md:text-lg">
              Madelyn, despite his initial reservations, quickly found himself
              deeply engaged with a demographic that was crucial to Symantek,
              LLC. His contentment became apparent when he started recommending
              our services within his professional circle, validating the
              success of our joint venture. Looking ahead, we are eager to
              expand upon this partnership, persistently generating impactful
              results.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudiesPage;
