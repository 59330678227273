import React from "react";

function SalesCard({ logo: Icon, headingText, paragraph }) {
  return (
    <div className="bg-white max-w-full md:max-w-sm lg:w-80 pb-8 rounded-xl px-5 pt-5 animate-slide-up flex flex-col items-start">
      <Icon size={24} className="text-purple_fixed" />
      <p className="text-purple_fixed font-bold text-lg md:text-xl pt-2">
        {headingText}
      </p>
      <p className="pt-2 text-sm md:text-base">{paragraph}</p>
    </div>
  );
}

export default SalesCard;
