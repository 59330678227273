import React from "react";
import AppointmentCard from "../Card/AppointmentCard";
import { FaUsers } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { IoMdCall } from "react-icons/io";

function AppointmentGeneration() {
  return (
    <div className="bg-sales_bg_purple pb-10">
      <div className="flex flex-col items-center justify-center pt-16">
        <div className="flex flex-col items-center justify-center md:w-[795px]  text-center px-9">
          <p className="font-bold text-2xl md:text-5xl text-purple_fixed">
            Proactive and Energetic
            <span className="font-bold text-2xl md:text-5xl text-black pt-1">
              {" "}
              Generation of Appointments via
            </span>
            <span className="font-bold text-2xl md:text-5xl text-black pt-1">
              {" "}
              LinkedIn
            </span>
          </p>
          <p className="text-center pt-7 text-lg sm:text-xl px-3">
            Our service is all-encompassing and designed for your convenience.
            We handle everything from pinpoint targeting and creating
            personalized outreach messages, to connecting with over 2,500
            qualified leads every month. We actively engage with these
            prospects, and take the necessary steps to book them in for a
            preliminary discussion directly on your calendar.
          </p>
        </div>
        {/* <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 pt-10"> */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          <AppointmentCard
            bgColor="#FED79D"
            icon={FaUsers}
            iconColor={"#FF961C"}
            iconBgColor={"#FFF6EB"}
            heading="1. Audience"
            text="Many organizations are yet to fully realize the potential that lies in social prospecting. Sales Navigator is brimming with unexplored data and potential leads. At Digital Marketing Lab, we tap into this invaluable resource, meticulously shaping and defining your ideal target audience."
          />
          <AppointmentCard
            bgColor="#FF7C7C"
            icon={GrMail}
            iconColor={"#F4253D"}
            iconBgColor={"#FFF1F3"}
            heading="2. Message"
            text="Every client at Digital Marketing Lab receives personalized attention when it comes to scriptwriting. We ensure each piece is in harmony with your unique narrative, target audience, and strategic approach. Partake in weekly consultations with the Founders of Digital Marketing Lab to continuously refine and adjust your messaging throughout the duration of your campaign."
          />
          <AppointmentCard
            bgColor="#FFD54A"
            icon={IoMdCall}
            iconColor={"#FFCD1D"}
            iconBgColor={"#FFFAF1"}
            heading="3. Schedule a Call"
            text="While others in our sector may connect you directly with the 2,500+ prospects we engage with monthly, Digital Marketing Lab takes a different approach. We handle all interactions and nurture a relationship with each potential client. By the time we introduce them to you, they feel like a familiar contact rather than a stranger."
          />
        </div>
      </div>
    </div>
  );
}

export default AppointmentGeneration;
