import RoutesPage from "./routes";

function App() {
  return (
    <div className="h-screen bg-gray-100" >
      <RoutesPage />
    </div>
  );
}

export default App;
