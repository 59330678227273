import React, { useState } from 'react'
import { FiPlus, FiMinus } from 'react-icons/fi';
import data from '../../utils/data/faqData.json'

function FaqPage() {
    const [isOpen, setIsOpen] = useState();
    const onClick = (id) => {
        if (isOpen === id) {
            setIsOpen(10)
        }
        else {
            setIsOpen(id)
        }
    }

    return (
        <div className='bg-gray-50 md:h-screen'>
            <div className='pt-16 pl-5 md:pt-48 pr-5 md:pr-0 md:pl-20 text-lg pb-20'>
                <div>
                    <p className='text-sm md:text-base'>You've Got Queries, </p>
                    <p className='pt-3 text-4xl md:text-6xl font-semibold'>We've Got <span className='text-purple_fixed'>Solutions</span></p>
                    <p className='pt-8 text-sm md:text-lg'>Wondering about our processes or have some queries in mind? Dive into the answers provided below, or feel free to reach out via email at info@digitalmarketinglab.com</p>
                </div>
                <div className='pt-24 md:pr-28'>
                    {
                        data.map((faq, index) => (
                            <div key={index} className="bg-white rounded-2xl md:rounded shadow-sm mb-3 border-2 border-black mt-10">
                                <div
                                    className={`flex justify-between items-center cursor-pointer p-7  ${isOpen === faq.id ? 'bg-purple_fixed rounded-xl md:rounded' : 'hover:bg-purple-100 transition-colors duration-200'}`}
                                    onClick={() => onClick(faq.id)}
                                >
                                    <div className={`font-semibold text-2xl md:text-3xl  ${isOpen ? 'text-black' : ''}`}>
                                        {faq.question}
                                    </div>
                                    <div>
                                        {isOpen === faq.id ? <FiMinus className="text-black" size={40} color='purple_fixed' /> : <FiPlus size={40} className="text-purple_fixed" />}
                                    </div>
                                </div>
                                {isOpen === faq.id && <div className="mt-2 p-7 text-2xl" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default FaqPage