import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import AboutUs from "./pages/AboutUs";
import Header from "./components/Header";
import FaqPage from "./pages/FAQ";
import CaseStudiesPage from "./pages/CaseStudies";
import BookingPage from "./pages/BookingPage";
import RegisterPage from "./pages/Register";
import LoginPage from "./pages/Login";
import SubscriptionPage from "./pages/Subscription";
import Success from "./pages/Subscription/success";
import Cancel from "./pages/Subscription/cancel";

const RoutesWithHeader = () => (
  <>
    <Header />
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/AboutUs" element={<AboutUs />} />
      <Route path="/caseStudies" element={<CaseStudiesPage />} />
      <Route path="/booking-page" element={<BookingPage />} />
    </Routes>
  </>
);

const RoutesPage = () => (
  <Router>
    <Routes>
      {/* Routes without Header */}
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/subscribe" element={<SubscriptionPage />} />
      <Route path="/success" element={<Success />} />
      <Route path="/cancel" element={<Cancel />} />

      {/* Nested Routes with Header */}
      <Route path="/" element={<RoutesWithHeader />}>
        {/* Redirect to sub-routes */}
        <Route index element={<LandingPage />} />
        <Route path="faq" element={<FaqPage />} />
        <Route path="AboutUs" element={<AboutUs />} />
        <Route path="caseStudies" element={<CaseStudiesPage />} />
        <Route path="booking-page" element={<BookingPage />} />
      </Route>
    </Routes>
  </Router>
);

export default RoutesPage;
