import React from "react";
import cancel from "../../media/cancel.png";

function Cancel() {
  return (
    <div className="bg-[#FDFDFD] min-h-screen flex flex-col justify-center items-center px-4">
      <img
        src={cancel}
        alt="error"
        className="w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 mx-auto mb-4"
      />
      <h3 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-center text-slate-700 mb-4">
        Something went wrong
      </h3>
      <a
        href="/"
        className="text-sm md:text-base lg:text-lg xl:text-xl uppercase bg-slate-900 text-white py-2 px-4 md:px-6 lg:px-8 rounded"
      >
        Go to homepage
      </a>
    </div>
  );
}

export default Cancel;
