// errorMessages.js

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    // Sign-in errors
    case "auth/invalid-email":
      return "The email address is badly formatted.";
    case "auth/user-disabled":
      return "This user has been disabled.";
    case "auth/user-not-found":
    case "auth/wrong-password":
      return "Invalid email or password.";
    case "auth/invalid-login-credentials":
      return "Invalid login credentials. Please try again.";

    // Sign-up errors
    case "auth/email-already-in-use":
      return "This email address is already in use.";
    case "auth/operation-not-allowed":
      return "Email/password accounts are not enabled.";
    case "auth/weak-password":
      return "The password is too weak.";

    // General or unknown errors
    default:
      return "An unexpected error occurred. Please try again.";
  }
};

export default getErrorMessage;
