import React, { useEffect, useState } from "react";
import firebase from "../../firebase/firebaseConfig";
import { createCheckoutSession } from "../../services/subscriptionService";
import plansData from "../../utils/data/plansData";
import LoadingSpinner from "../../utils/helper/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import Toast from "../../utils/helper/Toast";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

// diagonal - background;
function SubscriptionPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [planType, setPlanType] = useState("");
  const [isLoading, setIsLoading] = useState({ id: 0, loadingState: false });
  const navigate = useNavigate();
  const [toastInfo, setToastInfo] = useState({
    show: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        setUserName(user.displayName);
        const userRef = firebase.database().ref("users/" + user.uid);
        userRef.on("value", (snapshot) => {
          const userVal = snapshot.val();
          if (userVal) {
            console.log("userVal", userVal);
            setPlanType(userVal?.subscription?.planType || "");
          }
        });
      } else {
        setUserId("");
        setUserName("");
        setPlanType("");
      }
    });
  }, [userId]);

  const checkout = (plan, id) => {
    if (userId) {
      setIsLoading({ id: id, loadingState: true });
      createCheckoutSession(plan, userId)
        .then((response) => {
          const { session } = response.data;
          window.location = session.url;
          setIsLoading({ id: 0, loadingState: false });
        })
        .catch((error) => {
          console.error(error.response ? error.response.data : error.message);
          setIsLoading({ id: 0, loadingState: false });
        });
    } else {
      setToastInfo({
        show: true,
        message: "Please login first",
        type: "failure",
      });
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  };
  const handleSignout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setToastInfo({
          show: true,
          message: "Logged out successfully",
          type: "success",
        });
      })
      .catch((error) => {
        setToastInfo({
          show: true,
          message: "Something went wrong",
          type: "failure",
        });
      });
  };
  return (
    <>
      <div className="flex flex-col items-center w-full mx-auto min-h-screen bg-gray-100 overflow-x-hidden pb-10">
        {/* Header starts */}
        <div className="flex justify-between items-center w-full px-4 lg:px-6 h-20 bg-[#3d5fc4]">
          <div className="text-xl lg:text-4xl font-bold text-white">
            Pricing
          </div>
          <button
            className="text-white text-3xl lg:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            &#9776; {/* This is a simple hamburger icon */}
          </button>
          <div className="hidden lg:flex justify-center items-center gap-2">
            {/* The content that was here originally */}
            <div className="flex flex-col lg:flex-row justify-center items-center gap-2 my-2 lg:my-0 space-y-2 lg:space-y-0">
              {!userId ? (
                <a
                  href="/login"
                  className="bg-white px-4 py-1 lg:py-2 uppercase rounded-lg text-sm lg:text-xl text-[#4f7cff] font-semibold text-center"
                >
                  Login
                </a>
              ) : (
                <div className="flex flex-col lg:flex-row justify-center items-center space-x-0 lg:space-x-4 text-center">
                  <span className="text-white text-sm lg:text-xl">
                    {userName}
                  </span>
                  <button
                    onClick={() => handleSignout()}
                    className="bg-white px-4 py-1 lg:py-2 rounded-lg text-sm lg:text-base uppercase font-semibold text-[#4f7cff]"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Header ends */}

        {/* Dropdown starts */}
        {isMenuOpen && (
          <div className="lg:hidden flex flex-col items-center bg-[#3d5fc4] pb-4 w-full">
            {!userId ? (
              <a
                href="/login"
                className="bg-white px-4 py-2 uppercase rounded-lg text-base text-[#4f7cff] font-semibold my-2 text-center"
              >
                Login
              </a>
            ) : (
              <>
                <div className="text-white text-base my-2"> {userName}</div>
                <button
                  onClick={() => handleSignout()}
                  className="bg-white px-4 py-2 rounded-lg text-base uppercase font-semibold text-[#4f7cff] my-2"
                >
                  Logout
                </button>
              </>
            )}
          </div>
        )}
        {/* Dropdown ends */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center w-full md:w-11/12 lg:w-9/12 mx-auto mt-10 lg:mt-20">
          {plansData.map((item) => (
            <div
              key={item.id}
              className={`bg-white p-4 md:p-6 rounded-xl text-[#4f7cff] w-full mx-auto flex flex-col shadow-lg transition-all duration-300 ease-in-out
      ${
        planType === item.name.toLowerCase() && "ring-4 ring-green-400"
      } h-full`}
            >
              <img src={item.src} alt="" className="h-40 w-auto" />
              <h3 className="text-lg md:text-xl lg:text-2xl text-slate-700 text-center py-4 font-bold">
                {item.title}
              </h3>
              <ul className="flex-1 list-inside space-y-2 text-sm md:text-base lg:text-lg">
                {item?.features?.map((feature, index) => (
                  <li
                    key={index}
                    className={`flex items-center ${
                      feature.available ? "text-black" : "text-gray-500"
                    }`}
                  >
                    <span
                      className={`${
                        feature.available ? "bg-blue-500" : "bg-gray-500"
                      } rounded-full w-6 h-6 flex items-center justify-center mr-2`}
                    >
                      {feature.available ? (
                        <FaCheck className="text-white" />
                      ) : (
                        <FaTimes className="text-white" />
                      )}
                    </span>
                    {feature.text}
                  </li>
                ))}
              </ul>
              <div className="text-lg md:text-2xl lg:text-3xl font-bold py-4 text-center text-black">
                <span className="mr-1">${item.price}</span>
                <span className="text-sm md:text-lg">/mo</span>
              </div>
              <div className="mt-auto w-full flex justify-center items-center my-3">
                {planType === item.name.toLowerCase() ? (
                  <button
                    disabled
                    className="bg-green-600 text-white rounded-md text-sm lg:text-base uppercase px-4 py-2 font-bold"
                  >
                    Subscribed
                  </button>
                ) : (
                  <button
                    onClick={() => checkout(Number(item.price), item.id)}
                    disabled={planType}
                    className={`${
                      planType ? "bg-gray-500" : "bg-[#3d5fc4]"
                    } text-white rounded-md text-sm lg:text-base uppercase px-4 py-2 font-bold justify-center flex items-center`}
                  >
                    {isLoading.loadingState && isLoading.id === item.id ? (
                      <LoadingSpinner />
                    ) : (
                      "Book"
                    )}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Toast
        message={toastInfo.message}
        show={toastInfo.show}
        type={toastInfo.type}
        onClose={() => setToastInfo({ ...toastInfo, show: false })}
      />
    </>
  );
}

export default SubscriptionPage;
