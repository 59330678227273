import React from "react";
import { useNavigate } from "react-router-dom";

const SalesGoal = () => {
  const navigate = useNavigate();

  const goToPage = (link) => {
    navigate(link);
  };
  return (
    <div className="bg-gray-50 pt-24 md:pt-3 lg:pt-3 md:h-150 h-[500px] ">
      <div className="flex flex-col items-center md:justify-center h-full px">
        <div className="flex flex-col  lg:w-[1000px] ">
          <p className="text-center font-bold text-lg">LinkedIn Automation</p>
          <p className="text-4xl md:text-6xl font-semibold text-center ">
            Achieve Your Sales Targets,
            <span className="text-4xl md:text-6xl font-semibold text-purple_fixed">
              {" "}
              Prospects that Result in Conversions.
            </span>
          </p>
          <div className="flex mt-3">
            <p className="font-semibold text-center">
              Utilizing our advanced LinkedIn Automation tools, we reliably
              provide
              <span className="text-green-500 flex-nowrap">
                {" "}
                14-22+ high-quality{" "}
              </span>
              <span className="  flex-nowrap"> leads </span> monthly. Our
              assured{" "}
              <span className="text-green-500 flex-nowrap"> sales calls</span>{" "}
              effectively convert potential clients into profitable outcomes
              with ease.
            </p>
          </div>
        </div>
        <button
          onClick={() => goToPage("/booking-page")}
          className="mt-7 rounded-lg h-12 bg-gradient-to-r from-button_yellow to-purple_fixed text-white font-bold py-2 px-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
        >
          Book Free Call
        </button>
      </div>
    </div>
  );
};

export default SalesGoal;
