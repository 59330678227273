import React from "react";
import SalesGoal from "../../components/LandingPage/SalesGoal";
import MediaAds from "../../components/LandingPage/MediaAds";
import BrandsSection from "../../components/LandingPage/BrandsSection";
import AppointmentGeneration from "../../components/LandingPage/AppointmentGeneration";
import SalesCall from "../../components/LandingPage/SalesCall";
import Footer from "../../components/LandingPage/Footer";

function LandingPage() {
  return (
    <div>
      <SalesGoal />
      <MediaAds />
      <BrandsSection />
      <AppointmentGeneration />
      <SalesCall />
      <Footer />
    </div>
  );
}

export default LandingPage;
