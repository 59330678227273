import React from "react";
import call from "../../media/call.png";
import { useNavigate } from "react-router-dom";

function SalesCall() {
  const navigate = useNavigate();

  const goToPage = (link) => {
    navigate(link);
  };

  return (
    <div className="bg-white pb-14 md:pb-0">
      {/* Adjust the container to be flex and flex-col for mobile, and flex-row for lg screens */}
      <div className="flex flex-col lg:flex-row items-center justify-between px-4 md:px-10 lg:px-20 xl:px-32 py-10">
        {/* Image container - make it take half the space on large screens */}
        <div className="rounded-3xl border-[1px] border-black overflow-hidden md:w-2/3 lg:w-1/2 xl:w-1/2 mb-10 lg:mb-0">
          <img
            src={call}
            alt="Sales call illustration"
            className="object-cover w-full h-full"
          />
        </div>

        {/* Text container - also make it take half the space on large screens */}
        <div className="text-center lg:pl-10 lg:text-left lg:w-1/2 xl:w-1/2">
          <p className="font-bold text-2xl md:text-4xl lg:text-4xl xl:text-5xl">
            Prepared for
            <span className="text-green-500"> 25+ sales calls </span>
            Consultations Monthly?
          </p>
          <p className="pt-4 md:pt-7">
            Unsure if you can handle 25+ sales calls every month? No
            worries—let's start a conversation. Digital Marketing Lab excels in
            Lead Generation, and we’re here to tailor our services to meet your
            specific requirements.
          </p>
          <button
            onClick={() => goToPage("/booking-page")}
            className="mt-7 mb-10 md:mb-16 rounded-lg h-12 bg-gradient-to-r from-button_yellow to-purple_fixed text-white font-bold py-2 px-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          >
            I want to grow
          </button>
        </div>
      </div>
    </div>
  );
}

export default SalesCall;
