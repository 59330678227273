import React from "react";
import vrealm from "../../media/vrealm.png";
import ikanet from "../../media/ikanet.png";
import nayatel from "../../media/nayatel.png";
import cognizant from "../../media/cognizant.png";
import console from "../../media/console.png";

function BrandsSection() {
  return (
    <div className="bg-white pt-10 md:h-auto">
      <div className="flex flex-col items-center justify-center">
        <p className="font-bold text-2xl text-center md:text-xl">
          Trusted by brands all over the world
        </p>
        <div className="flex flex-col pt-10 animate-slide-up md:flex-row md:flex-wrap md:justify-center">
          <img
            src={vrealm}
            alt="vrealm"
            className="w-36 h-32 md:w-24 md:h-24"
          />
          <img
            src={ikanet}
            alt="vrealm"
            className="w-48 h-32 md:w-32 md:h-24"
          />
          <img
            src={nayatel}
            alt="vrealm"
            className="w-48 h-32 md:w-32 md:h-24"
          />
          <img
            src={cognizant}
            alt="vrealm"
            className="w-52 h-32 pl-4 md:w-36 md:h-24"
          />
          <img
            src={console}
            alt="vrealm"
            className="w-36 h-32 pl-4 md:w-24 md:h-24"
          />
        </div>
      </div>
    </div>
  );
}

export default BrandsSection;
