import React from "react";
import digitalMarketingLabAiLogoWithoutbg from "../../media/digitalMarketingLabAiLogoWithoutbg.png";

function Footer() {
  return (
    <div className="bg-footer_grey_color">
      <div className="flex flex-col md:flex-row items-center justify-between px-6 md:px-20 lg:px-32 xl:px-40 py-6">
        <img
          src={digitalMarketingLabAiLogoWithoutbg}
          alt="Logo"
          className="w-24 h-16 md:w-32 md:h-24 lg:w-48 lg:h-32 mb-4 md:mb-0 mx-auto md:mx-0"
        />
        <p className="text-center text-sm md:text-base lg:text-lg xl:text-xl">
          © 2023 Digital Marketing Lab. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
