import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const createCheckoutSession = (plan, customerId) => {
  return axios.post(`${BASE_URL}/api/v1/create-subscription-checkout-session`, {
    plan,
    customerId,
  });
};

export const paymentSuccess = (sessionId, userId) => {
  return axios.post(`${BASE_URL}/api/v1/payment-success`, {
    sessionId,
    firebaseId: userId,
  });
};
