// Import the SVG assets
import BasicIcon from "../../media/basic.svg"; // Assume these are SVG React components
import ProIcon from "../../media/pro.svg";
import BusinessIcon from "../../media/business.svg";

const plansData = [
  {
    id: 1,
    src: BasicIcon,
    name: "basic",
    title: "Basic",
    price: "500",
    features: [
      { available: true, text: "Fresh and accurate Email Outreach Engine." },
      { available: true, text: "Fresh CRM Setup." },
      { available: true, text: "CRM Reminders" },
      { available: true, text: "With 5 successful closings exclusively." },
      { available: false, text: "Follow-Up Email Templates" },
      { available: false, text: "Call Script And Training" },
      { available: false, text: "Sales Presentation Templates" },
      { available: false, text: "Call Recording Reviewing" },
      { available: false, text: "Email Deliverability Optimization" },
      { available: false, text: "Ideal Client Persona Makeover" },
      { available: false, text: "Unique Selling Proposition (USP) Makeover." },
      { available: false, text: "Offer Makeover" },
      { available: false, text: "LinkedIn Profile Optimization" },
      { available: false, text: "Client Acquisition Sales Video Script" },
    ],
  },
  {
    id: 2,
    src: ProIcon,
    name: "pro",
    title: "Popular",
    price: "1000",
    features: [
      { available: true, text: "Fresh and accurate Email Outreach Engine." },
      { available: true, text: "Fresh CRM Setup." },
      { available: true, text: "CRM Reminders" },
      { available: true, text: "Follow-Up Email Templates" },
      { available: true, text: "Call Script And Training" },
      { available: true, text: "Sales Presentation Templates" },
      { available: true, text: "Call Recording Reviewing" },
      { available: true, text: "With 10 successful closings exclusively." },
      { available: false, text: "Email Deliverability Optimization" },
      { available: false, text: "Ideal Client Persona Makeover" },
      { available: false, text: "Unique Selling Proposition (USP) Makeover." },
      { available: false, text: "Offer Makeover" },
      { available: false, text: "LinkedIn Profile Optimization" },
      { available: false, text: "Client Acquisition Sales Video Script" },
    ],
  },
  {
    id: 3,
    src: BusinessIcon,
    name: "business",
    title: "Icebreaker",
    price: "1499",
    features: [
      { available: true, text: "Fresh and accurate Email Outreach Engine." },
      { available: true, text: "Fresh CRM Setup." },
      { available: true, text: "CRM Reminders" },
      { available: true, text: "Follow-Up Email Templates" },
      { available: true, text: "Call Script And Training" },
      { available: true, text: "Sales Presentation Templates" },
      { available: true, text: "Call Recording Reviewing" },
      { available: true, text: "Email Deliverability Optimization" },
      { available: true, text: "Ideal Client Persona Makeover" },
      { available: true, text: "Unique Selling Proposition (USP) Makeover." },
      { available: true, text: "Offer Makeover" },
      { available: true, text: "LinkedIn Profile Optimization" },
      { available: true, text: "Client Acquisition Sales Video Script" },
      { available: true, text: "With 15 successful closings exclusively." },
    ],
  },
];

export default plansData;
