import React, { useState } from "react";
import Toast from "../../utils/helper/Toast";
import firebase from "../../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import getErrorMessage from "../../utils/helper/errorMessage";
import LoadingSpinner from "../../utils/helper/LoadingSpinner";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [toastInfo, setToastInfo] = useState({
    show: false,
    message: "",
    type: "success",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Check if fields are filled
      if (!email || !password) {
        setToastInfo({
          show: true,
          message: "Please fill all the fields",
          type: "failure",
        });
        return;
      }

      // Validate email format
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        setToastInfo({
          show: true,
          message: "Please provide a valid email",
          type: "failure",
        });
        return;
      }

      // Attempt to sign in the user
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      // Handle successful sign in
      if (response.user) {
        setToastInfo({
          show: true,
          message: "User signed in successfully",
          type: "success",
        });
        setTimeout(() => {
          setIsLoading(false);
          setEmail("");
          setPassword("");
          navigate("/subscribe");
        }, 3000); // Adjust the time (3000ms = 3 seconds) as needed for your toast display duration
      }
    } catch (error) {
      // Handle any errors during the sign-in process
      const userFriendlyMessage = getErrorMessage(error.code);
      setToastInfo({
        show: true,
        message: userFriendlyMessage,
        type: "failure",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-center w-full mx-auto h-screen ">
        <form
          onSubmit={handleSubmit}
          className="grid place-items-center w-11/12 mx-auto bg-white text-[#4f7cff] shadow-2xl rounded-3xl lg:w-5/12"
        >
          <div className="pt-16 pb-4 text-2xl lg:text-3xl font-bold capitalize">
            Login
          </div>
          {/* email */}
          <div className="w-full flex flex-col px-6 py-4 lg:px-14 lg:py-6">
            <label>Email</label>
            <input
              type="email"
              className="w-full border border-gray-300 rounded-lg px-3 py-3 mt-1 text-lg outline-none"
              required
              placeholder="example@123.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* password */}
          <div className="w-full flex flex-col px-6 py-4 lg:px-14 lg:py-8">
            <label>Password</label>
            <input
              type="password"
              className="w-full border border-gray-300 rounded-lg px-3 py-3 mt-1 text-lg outline-none"
              required
              placeholder="******"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="w-full flex justify-between items-center px-6 pb-4 lg:px-14 lg:pb-8 text-[#3d5fc4]">
            <div>Don't have an account?</div>
            <div>
              <a href="/register" className="hover:underline">
                Register Now
              </a>
            </div>
          </div>
          <div className="mx-auto flex justify-center items-center pt-4 lg:pt-6 pb-8 lg:pb-16">
            <button
              type="submit"
              className={`bg-[#3d5fc4] text-white rounded-md text-base uppercase w-24 py-2 flex justify-center items-center ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading ? <LoadingSpinner /> : "Login"}
            </button>
          </div>
        </form>
      </div>
      <Toast
        message={toastInfo.message}
        show={toastInfo.show}
        type={toastInfo.type}
        onClose={() => setToastInfo({ ...toastInfo, show: false })}
      />
    </>
  );
}

export default LoginPage;
