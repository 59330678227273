import React, { useEffect, useState } from "react";
import success from "../../media/success.png";
import firebase from "../../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { paymentSuccess } from "../../services/subscriptionService";
import LoadingSpinner from "../../utils/helper/LoadingSpinner";
function Success() {
  const [userId, setUserId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        const userRef = firebase.database().ref("users/" + user.uid);
        userRef.on("value", (snapshot) => {
          const userVal = snapshot.val();
          if (userVal) {
            setSessionId(userVal.subscription.sessionId || "");
          }
        });
      }
    });
  }, [userId, sessionId]);

  const handlePaymentSuccess = () => {
    setIsLoading(true);
    paymentSuccess(sessionId, userId)
      .then((response) => {
        setIsLoading(false);
        navigate("/subscribe");
      })
      .catch((error) => {
        console.error(error.response ? error.response.data : error.message);
      });
  };

  return (
    <div className="bg-[#FDFDFD] min-h-screen flex justify-center items-center">
      <div className="text-center p-4">
        <img
          src={success}
          alt="success"
          className="w-36 h-36 md:w-52 md:h-52 mx-auto"
        />
        <h3 className="text-xl md:text-3xl pt-8 font-bold text-slate-700">
          Payment successful
        </h3>
        <button
          onClick={() => handlePaymentSuccess()}
          className={`${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }mt-8 mb-4 px-6 py-2 text-sm md:text-lg uppercase bg-[#009c96] text-white rounded`}
          disabled={isLoading}
        >
          {isLoading ? <LoadingSpinner /> : "Proceed"}
        </button>
      </div>
    </div>
  );
}

export default Success;
